import { Form, FormLayout, Modal, Select, TextField, ButtonGroup, Button } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { getLatestOption, getRequest, postRequest, putRequest } from "../functions";
import { ModelString } from "./modelStringSelect";
import { getCodeString, initialLabel, initialSerial, modelInfo, saveStringInRemark, statusOptions, getOptions } from "./helpers";
import { SerialSelect } from "./serialSelect";
import { useAuthStore, useLookupStore } from "../../../utils/useStore";

export const SerialsModal = ({ modalActive, closeModal, isEditSerial, isRFID, serialToEdit, setSerialToast, setLabelToast }) => {
  const [serial, setSerial] = useState(serialToEdit || initialSerial);
  const [modelString, setModelString] = useState();
  const [modelStringTouched, setModelStringTouched] = useState(false);
  const [nextSerialnumber, setNextSerialnumber] = useState();
  const [printLabel, setPrintLabel] = useState(initialLabel);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [savingResult, setSavingResult] = useState(false);
  const [creatingNewSerial, setCreatingNewSerial] = useState(!isEditSerial);
  const [serialError, setSerialError] = useState(false);

  const user = useAuthStore();
  const users = useLookupStore((state) => state.users);
  const producttypes = useLookupStore((state) => state.producttypes);
  const recipetypes = useLookupStore((state) => state.recipetypes);
  const softwaretypes = useLookupStore((state) => state.softwaretypes);
  const hardwaretypes = useLookupStore((state) => state.hardwaretypes);
  const hardwareboilertypes = useLookupStore((state) => state.hardwareboilertypes);

  const userOptions = getOptions(
    users.filter((u) => !u.Inactief),
    "GebruikerID",
    "Afkorting"
  );
  const productOptions = getOptions(producttypes, "ProductTypeID", "Product");
  const recipeOptions = getOptions(recipetypes, "RecipeTypeID", "Recipe");
  const softwareOptions = getOptions(softwaretypes, "SofwareTypeID", "Software");
  const hardwareOptions = getOptions(hardwaretypes, "HardwareTypeID", "Hardware");
  const hardwareBoilerOptions = getOptions(hardwareboilertypes, "HardwareBoilerTypeID", "HardwareBoiler");

  useEffect(() => {
    async function getNewSerialnumber() {
      let model, prevString, lastSerial, sn, remark;

      // get code string from previous or current serial number remark
      if (isRFID || creatingNewSerial) {
        lastSerial = await getRequest(`/api/serials?limit=1`);
        remark = lastSerial[0]?.remarks;
        sn = lastSerial[0]?.serialnumber;
      } else {
        remark = serial.remarks;
      }

      // set model string details from previous remark
      if (remark?.includes("***")) {
        prevString = getCodeString(remark); //***PMG10-LF01-1***
        model = prevString.substring(3, 8);
        const cable = prevString.substring(9, 10);
        const letter = prevString.substring(10, 11);
        const color = prevString.substring(11, 13);
        const variant = prevString.substring(14, 15);
        setModelString({
          model: { label: model, value: model },
          cable: { label: cable, value: cable },
          letter: { label: letter, value: letter },
          color: { label: color, value: color },
          variant: { label: variant, value: variant },
        });
      } else {
        setModelString({});
      }

      // generate new serialnumber + remark + latest options for new serial
      if (creatingNewSerial) {
        sn = lastSerial[0]?.serialnumber;
        setNextSerialnumber(sn + 1);
        setSerial((serial) => ({
          ...serial,
          serialnumber: sn + 1,
          remarks: prevString,
          softwareTypeID: getLatestOption(softwareOptions),
          recipeTypeID: getLatestOption(recipeOptions),
          productTypeID: getLatestOption(productOptions),
          hardwareTypeID: getLatestOption(hardwareOptions),
          hardwareBoilerTypeID: getLatestOption(hardwareBoilerOptions),
          qualityCheckByUserID: user && userOptions && userOptions.length > 0 ? userOptions.find((u) => Number(u.value) === user.user.id)?.value : null,
        }));
      }

      // set label details
      if (isRFID)
        setPrintLabel((r) => ({
          ...r,
          SerialNr: creatingNewSerial ? sn + 1 : sn,
          Model: model,
          RfidModelId: modelInfo(model, modelString?.cable?.value + modelString?.letter?.value).RfidModelId,
          TechnicalInfo: modelInfo(model, modelString?.cable?.value + modelString?.letter?.value).TechnicalInfo,
        }));

      setDataLoaded(true);
    }
    getNewSerialnumber();
  }, [creatingNewSerial]);

  const handleChange = (value, id) => {
    setSerial((serial) => ({
      ...serial,
      [id]: value,
    }));
  };

  const handlePrintLabelChange = async (value, id) => {
    if (id === "SerialNr") {
      const data = await getRequest(`/api/serials/${value}`);
      if (data) {
        setSerial(data);
        if (!data.remarks.includes("***")) setModelString({});
      }
    }
    setPrintLabel((s) => ({
      ...s,
      [id]: value,
    }));
  };

  const handleSave = async () => {
    setSavingResult(true);
    //save code in serial.remarks for the following serial nr
    serial.remarks = saveStringInRemark(modelString, serial.remarks);

    if (serial.remarks) {
      if (creatingNewSerial) await postRequest("/api/serials", serial, setSerialToast);
      if ((!creatingNewSerial && !isRFID) || modelStringTouched) await putRequest(`/api/serials/${serial.serialnumber}`, serial, setSerialToast);
      if (isRFID) await postRequest("/api/rfid_events/printlabel", printLabel, setLabelToast);
      setSerialError(false);
      closeModal();
    } else {
      setSerialError(true);
    }
    setSavingResult(false);
  };

  return (
    <Modal
      open={modalActive}
      onClose={closeModal}
      size="large"
      loading={!dataLoaded}
      title={isRFID ? "Create RFID print label" : `${creatingNewSerial ? "Add" : "Edit"} serial number ${dataLoaded ? "#" + serial.serialnumber : ""}`}
      primaryAction={{ content: "Save", onAction: handleSave, loading: savingResult }}
      secondaryActions={[{ content: "Cancel", onAction: closeModal }]}>
      {isRFID ? (
        <Modal.Section>
          <ButtonGroup variant="segmented" fullWidth>
            <Button size="large" pressed={creatingNewSerial} onClick={() => setCreatingNewSerial(true)}>
              {`Add new serial number #${nextSerialnumber}`}
            </Button>
            <Button
              size="large"
              pressed={!creatingNewSerial}
              onClick={() => {
                setCreatingNewSerial(false);
                setPrintLabel((s) => ({ ...s, SerialNr: serial.serialnumber - 1 }));
                setSerial((s) => ({ ...s, serialnumber: serial.serialnumber - 1 }));
              }}>
              Create label for existing serial number
            </Button>
          </ButtonGroup>
        </Modal.Section>
      ) : null}

      <Modal.Section>
        <Form implicitSubmit onSubmit={handleSave}>
          {(isRFID && creatingNewSerial) || !isRFID ? (
            <FormLayout>
              <FormLayout.Group>
                <ModelString modelString={modelString} setModelString={setModelString} printLabel={printLabel} setPrintLabel={setPrintLabel} disabled={false} />
              </FormLayout.Group>
              <FormLayout.Group>
                {/* <Select
                  id="productTypeID"
                  label="Product"
                  options={productOptions}
                  onChange={handleChange}
                  defaultValue={
                    productOptions && productOptions.length > 0
                      ? productOptions.reduce((prev, current) =>
                          prev.ProductTypeID > current.ProductTypeID
                            ? prev
                            : current
                        )
                      : null
                  }
                  value={serial.productTypeID?.toString()}
                /> */}
                <Select id="recipeTypeID" label="Recipe" options={recipeOptions} onChange={handleChange} value={serial.recipeTypeID?.toString()} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select id="hardwareTypeID" label="Hardware" options={hardwareOptions} onChange={handleChange} value={serial.hardwareTypeID?.toString()} />
                <Select id="hardwareBoilerTypeID" label="Hardware boiler" options={hardwareBoilerOptions} value={serial.hardwareBoilerTypeID?.toString()} onChange={handleChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select id="softwareTypeID" label="Software" options={softwareOptions} onChange={handleChange} value={serial.softwareTypeID?.toString()} />
                <Select id="Status" label="Status" options={statusOptions} onChange={handleChange} value={serial.Status} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField id="qualityCheckDate" label="Quality check date" type="date" value={serial.qualityCheckDate} onChange={handleChange} />
                <Select
                  id="qualityCheckByUserID"
                  label="Quality check by user"
                  options={userOptions}
                  onChange={handleChange}
                  value={serial.qualityCheckByUserID?.toString() || user.user.id?.toString() || ""}
                  placeholder="Select user..."
                />
              </FormLayout.Group>
              <TextField id="RfidLocation" label="RFID STATION" maxLength={50} value={serial.RfidLocation || ""} onChange={handleChange} autoComplete="off" />
              <FormLayout.Group>
                <TextField id="remarks" label="Remarks" maxLength={500} multiline={10} showCharacterCount value={serial.remarks || ""} onChange={handleChange} autoComplete="off" />
              </FormLayout.Group>

              {isRFID ? (
                <FormLayout.Group>
                  <TextField id="ManufactureYear" label="Manufacture year on print label" value={printLabel.ManufactureYear} onChange={handlePrintLabelChange} />
                </FormLayout.Group>
              ) : null}
            </FormLayout>
          ) : isRFID && !creatingNewSerial ? (
            <FormLayout>
              <FormLayout.Group>
                <SerialSelect id="SerialNr" value={printLabel.SerialNr} onChange={handlePrintLabelChange} label="Serial number" />
                <TextField id="ManufactureYear" label="Manufacture year on print label" value={printLabel.ManufactureYear} onChange={handlePrintLabelChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <ModelString
                  modelString={modelString}
                  setModelString={setModelString}
                  printLabel={printLabel}
                  setPrintLabel={setPrintLabel}
                  updateSerialString={true}
                  serialError={serialError}
                  setModelStringTouched={setModelStringTouched}
                />
              </FormLayout.Group>
            </FormLayout>
          ) : null}
        </Form>
      </Modal.Section>
    </Modal>
  );
};
